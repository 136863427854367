@import "../../../Import/SCSS/classes";

.Debug
{
    margin: 20px;
    font-size: 0.6em;

    td
    {
        padding: 1em 0.5em;
        line-height: 1.1;
    }

    tr
    {
        position: relative;
        border-top: 1px solid currentColor;
    }
}

.DebugTitleCell
{
    min-width: 300px;
    padding-right: 2em !important;
    padding-bottom: 2em !important;

    h3
    {
        font-size: 1.5em;
    }

    p
    {
        margin: 0.5em 0;
        font-style: italic;
    }

}

.DebugWrapper
{
    @include overlay;

    h2
    {
        margin: 60px 20px 20px;
    }

    overflow: auto;
}